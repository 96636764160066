import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a73dba98 = () => interopDefault(import('../pages/theme-4/download/index.vue' /* webpackChunkName: "pages/theme-4/download/index" */))
const _d49f3daa = () => interopDefault(import('../pages/theme-4/games/index.vue' /* webpackChunkName: "pages/theme-4/games/index" */))
const _356abc0c = () => interopDefault(import('../pages/theme-4/hold-balance/index.vue' /* webpackChunkName: "pages/theme-4/hold-balance/index" */))
const _3cf0f3a7 = () => interopDefault(import('../pages/theme-4/leaderboard/index.vue' /* webpackChunkName: "pages/theme-4/leaderboard/index" */))
const _ba95bd60 = () => interopDefault(import('../pages/theme-4/login.vue' /* webpackChunkName: "pages/theme-4/login" */))
const _662e3328 = () => interopDefault(import('../pages/theme-4/no-support.vue' /* webpackChunkName: "pages/theme-4/no-support" */))
const _836c1222 = () => interopDefault(import('../pages/theme-4/not-found.vue' /* webpackChunkName: "pages/theme-4/not-found" */))
const _d1b4375c = () => interopDefault(import('../pages/theme-4/play-game.vue' /* webpackChunkName: "pages/theme-4/play-game" */))
const _64204de6 = () => interopDefault(import('../pages/theme-4/promotion/index.vue' /* webpackChunkName: "pages/theme-4/promotion/index" */))
const _754c3b54 = () => interopDefault(import('../pages/theme-4/recently/index.vue' /* webpackChunkName: "pages/theme-4/recently/index" */))
const _3f9efa77 = () => interopDefault(import('../pages/theme-4/refer-friend/index.vue' /* webpackChunkName: "pages/theme-4/refer-friend/index" */))
const _32a0b58c = () => interopDefault(import('../pages/theme-4/register.vue' /* webpackChunkName: "pages/theme-4/register" */))
const _3f652f51 = () => interopDefault(import('../pages/theme-4/download/android.vue' /* webpackChunkName: "pages/theme-4/download/android" */))
const _778f06ef = () => interopDefault(import('../pages/theme-4/download/ios.vue' /* webpackChunkName: "pages/theme-4/download/ios" */))
const _71d9ce2d = () => interopDefault(import('../pages/theme-4/player/deposits/index.vue' /* webpackChunkName: "pages/theme-4/player/deposits/index" */))
const _940161c8 = () => interopDefault(import('../pages/theme-4/player/withdrawals/index.vue' /* webpackChunkName: "pages/theme-4/player/withdrawals/index" */))
const _53f761da = () => interopDefault(import('../pages/theme-4/promotion/bonus.vue' /* webpackChunkName: "pages/theme-4/promotion/bonus" */))
const _46ccd5b7 = () => interopDefault(import('../pages/theme-4/player/deposits/bank-transfer.vue' /* webpackChunkName: "pages/theme-4/player/deposits/bank-transfer" */))
const _a4c78ba2 = () => interopDefault(import('../pages/theme-4/player/deposits/crypto-payment.vue' /* webpackChunkName: "pages/theme-4/player/deposits/crypto-payment" */))
const _1c1234c1 = () => interopDefault(import('../pages/theme-4/player/deposits/online-payment.vue' /* webpackChunkName: "pages/theme-4/player/deposits/online-payment" */))
const _11d76681 = () => interopDefault(import('../pages/theme-4/player/deposits/quick-payment.vue' /* webpackChunkName: "pages/theme-4/player/deposits/quick-payment" */))
const _1477aaa0 = () => interopDefault(import('../pages/theme-4/player/withdrawals/bank-withdrawal.vue' /* webpackChunkName: "pages/theme-4/player/withdrawals/bank-withdrawal" */))
const _73d4fdea = () => interopDefault(import('../pages/theme-4/player/withdrawals/crypto-withdrawal.vue' /* webpackChunkName: "pages/theme-4/player/withdrawals/crypto-withdrawal" */))
const _4de5038e = () => interopDefault(import('../pages/theme-4/index.vue' /* webpackChunkName: "pages/theme-4/index" */))
const _bc3820aa = () => interopDefault(import('../pages/theme-4/_type/_id.vue' /* webpackChunkName: "pages/theme-4/_type/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/download",
    component: _a73dba98,
    name: "download"
  }, {
    path: "/games",
    component: _d49f3daa,
    name: "games"
  }, {
    path: "/hold-balance",
    component: _356abc0c,
    name: "hold-balance"
  }, {
    path: "/leaderboard",
    component: _3cf0f3a7,
    name: "leaderboard"
  }, {
    path: "/login",
    component: _ba95bd60,
    name: "login"
  }, {
    path: "/no-support",
    component: _662e3328,
    name: "no-support"
  }, {
    path: "/not-found",
    component: _836c1222,
    name: "not-found"
  }, {
    path: "/play-game",
    component: _d1b4375c,
    name: "play-game"
  }, {
    path: "/promotion",
    component: _64204de6,
    name: "promotion"
  }, {
    path: "/recently",
    component: _754c3b54,
    name: "recently"
  }, {
    path: "/refer-friend",
    component: _3f9efa77,
    name: "refer-friend"
  }, {
    path: "/register",
    component: _32a0b58c,
    name: "register"
  }, {
    path: "/download/android",
    component: _3f652f51,
    name: "download-android"
  }, {
    path: "/download/ios",
    component: _778f06ef,
    name: "download-ios"
  }, {
    path: "/player/deposits",
    component: _71d9ce2d,
    name: "player-deposits"
  }, {
    path: "/player/withdrawals",
    component: _940161c8,
    name: "player-withdrawals"
  }, {
    path: "/promotion/bonus",
    component: _53f761da,
    name: "promotion-bonus"
  }, {
    path: "/player/deposits/bank-transfer",
    component: _46ccd5b7,
    name: "player-deposits-bank-transfer"
  }, {
    path: "/player/deposits/crypto-payment",
    component: _a4c78ba2,
    name: "player-deposits-crypto-payment"
  }, {
    path: "/player/deposits/online-payment",
    component: _1c1234c1,
    name: "player-deposits-online-payment"
  }, {
    path: "/player/deposits/quick-payment",
    component: _11d76681,
    name: "player-deposits-quick-payment"
  }, {
    path: "/player/withdrawals/bank-withdrawal",
    component: _1477aaa0,
    name: "player-withdrawals-bank-withdrawal"
  }, {
    path: "/player/withdrawals/crypto-withdrawal",
    component: _73d4fdea,
    name: "player-withdrawals-crypto-withdrawal"
  }, {
    path: "/",
    component: _4de5038e,
    name: "index"
  }, {
    path: "/:type/:id?",
    component: _bc3820aa,
    name: "type-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
