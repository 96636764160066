import { render, staticRenderFns } from "./PromotionPage.vue?vue&type=template&id=61389ddf&scoped=true"
import script from "./PromotionPage.vue?vue&type=script&lang=js"
export * from "./PromotionPage.vue?vue&type=script&lang=js"
import style0 from "./PromotionPage.vue?vue&type=style&index=0&id=61389ddf&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61389ddf",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Theme4DesktopHomeSlider: require('/app/components/Theme4/Desktop/HomeSlider.vue').default})
