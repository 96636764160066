import { render, staticRenderFns } from "./MainCard.vue?vue&type=template&id=3cf93cf2"
import script from "./MainCard.vue?vue&type=script&lang=js"
export * from "./MainCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Theme4DesktopHomeSlider: require('/app/components/Theme4/Desktop/HomeSlider.vue').default,Theme4DesktopHotGame: require('/app/components/Theme4/Desktop/HotGame.vue').default,Theme4DesktopLiveCasino: require('/app/components/Theme4/Desktop/LiveCasino.vue').default,Theme4DesktopSportGame: require('/app/components/Theme4/Desktop/SportGame.vue').default,Theme4DesktopBonusGame: require('/app/components/Theme4/Desktop/BonusGame.vue').default,Theme4DesktopPromotionComponent: require('/app/components/Theme4/Desktop/PromotionComponent.vue').default,Theme4DesktopGameAndSport: require('/app/components/Theme4/Desktop/GameAndSport.vue').default,Theme4DesktopHomeFooter: require('/app/components/Theme4/Desktop/HomeFooter.vue').default,Theme4Popup: require('/app/components/Theme4/Popup.vue').default,Theme4MobileHomeSlider: require('/app/components/Theme4/Mobile/HomeSlider.vue').default,Theme4MobileBonusGame: require('/app/components/Theme4/Mobile/BonusGame.vue').default,Theme4MobilePromotionComponent: require('/app/components/Theme4/Mobile/PromotionComponent.vue').default})
