import { render, staticRenderFns } from "./BonusPage.vue?vue&type=template&id=dda1e842&scoped=true"
import script from "./BonusPage.vue?vue&type=script&lang=js"
export * from "./BonusPage.vue?vue&type=script&lang=js"
import style0 from "./BonusPage.vue?vue&type=style&index=0&id=dda1e842&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dda1e842",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Theme4DesktopHomeSlider: require('/app/components/Theme4/Desktop/HomeSlider.vue').default})
